.container {
  font-family: 'Roboto';
  margin: auto;
  padding: 0 15px;
  width: 90vw;
}

.section {
  background-color: transparent;
}

.section h2 {
  background-color: black;
  color: white;
  font-family: 'Roboto';
  font-weight: bold;
  padding: 10px;
}

.card-list {
  align-items: flex-start;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
}

.card {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  max-width: 150px;
  padding: 5px;
  text-align: center;
}

.user-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: 64px;
  width: 100px;
}

.user-icon svg {
  height: 100%;
  transition: all .4s;
  width: 100%;
}

.user-icon:hover svg,
.user-icon:focus svg {
  fill: red;
}

.button-list {
  align-items: flex-start;
  display: flex;
}